import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import styled from 'styled-components';
import { useCollection } from '../context/collection';

export default () => {
	const { iconURL } = useCollection();

	return (
		<AppBar position="static" style={{ background: 'none', height: '80px', zIndex: '1000000' }}>
			<StyledToolbar style={{ background: 'none' }}>
				<StyledIcon src="././Assets/logo.jpg" width="88" height="88" alt="AyeYahZee Logo" className="Shop__icon" />
			</StyledToolbar>
		</AppBar>
	);
};

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media (min-width: 600px) {
    height: 70px;
  }

  @media (min-width: 1080px) {
    height: 80px;
  }
`;

const StyledIcon = styled.img`
  height: 40px;
  width: auto;

  @media (min-width: 600px) {
    height: 50px;
  }

  @media (min-width: 1080px) {
    height: 60px;
  }
`;
