import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo
} from 'react';
import chroma from 'chroma-js';
import Loading from '../components/Loading';
import Error from '../components/Error';

const initialState = {
	collectionID: '',
	name: '',
	banners: [],
	iconURL: '',
	accentColor: '#FFC024',
	tagline: ''
};

const mockResponse = {
	shopifyID: '397296435445',
	name: 'Shop 6',
	icon: {
		url:
			'https://merchforall-content.nyc3.digitaloceanspaces.com/600ede950affe9000a8583a9/e41870be-6ba6-4629-baff-cf4e78b251bb'
	},
	banners: [
		{
			url:
				'https://merchforall-content.nyc3.digitaloceanspaces.com/5fe1e3707957f2000915cb46/26232d27-f4a7-4e9f-b7a7-27fceef1fcd5'
		},
		{
			url:
				'https://merchforall-content.nyc3.digitaloceanspaces.com/600ede2463970e000abe96fd/65d10b72-99d1-4a41-bc9a-dd048a190270'
		}
	],
	accentColor: '#FFC024'
};

const CollectionContext = createContext(initialState);

export const CollectionProvider = ({ children }) => {
	const [shopifyID, setShopifyID] = useState('');
	const [name, setName] = useState('');
	const [banners, setBanners] = useState([]);
	const [iconURL, setIconURL] = useState('');
	const [accentColor, setAccentColor] = useState('');
	const accentColorLuminance = useMemo(
		() => (accentColor ? chroma(accentColor).luminance() : 0.5),
		[accentColor]
	);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(true);

	useEffect(() => {
		const requestStoreInfo = async () => {
			try {
				if (process.env.NODE_ENV === 'development') {
					setName(mockResponse.name);
					setIconURL(mockResponse.url);
					setShopifyID(397296435445);
					setBanners(mockResponse.banners.map((banner) => banner.url));
					setAccentColor(mockResponse.accentColor);
					setError(false);
				} else {
					setError(false);

					const titleElem = document.getElementById('title');
					if (titleElem?.text && typeof titleElem?.text === 'string')
						setName(titleElem.text.replace(' | Merch For All', ''));

					const faviconElem = document.getElementById('favicon');
					if (faviconElem?.href) setIconURL(faviconElem?.href);

					const shopifyElem = document.getElementById('shopify');
					if (shopifyElem?.content && shopifyElem?.content !== 'undefined')
						setShopifyID(397296435445);
					else setError(true);

					const bannersElem = document.getElementById('banners');
					if (bannersElem?.content)
						setBanners(bannersElem?.content?.split(';;;'));

					const accentColor = document.getElementById('accentColor');
					if (accentColor?.content) setAccentColor(accentColor?.content);
				}

				setLoading(false);
			} catch (error) {
				console.log('error', error);
				setLoading(false);
				setError(true);
			}
		};

		requestStoreInfo();
	}, []);

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <Error />;
	}

	return (
		<CollectionContext.Provider
			value={{
				shopifyID,
				name,
				banners,
				iconURL,
				accentColor,
				accentColorLuminance
			}}
		>
			{children}
		</CollectionContext.Provider>
	);
};

export const useCollection = () => {
	const collectionContext = useContext(CollectionContext);
	return collectionContext;
};
